<template>
<div v-if="badges">
<ion-grid>
    <ion-row v-for="badge in badges" :key="badge.id">
        <ion-col>
            
            <ion-card>
                <ion-card-header>
                    <ion-card-subtitle>{{badge.Id}}</ion-card-subtitle>
                    <ion-card-title>{{ badge.Name }}</ion-card-title>
                </ion-card-header>

                <ion-card-content>
                    {{badge.Description}}
                </ion-card-content>

            </ion-card>    
                
        </ion-col>
    </ion-row>
</ion-grid>
</div>
<div v-if="!badges">
  <loading-template />
</div>
</template>

<script>
import axios from "axios";
import { IonCol, IonGrid, IonRow, IonCard } from '@ionic/vue';
import LoadingTemplate from '@/components/System/Loading.vue'
export default {
  name: "get-request",
  components: { IonCol, IonGrid, IonRow, IonCard, LoadingTemplate },
  data() {
    return {
      badges: null,
    };
  },
  created() {
    // Simple GET request using axios
    const config = {
      headers: {
        "Accept": "application/json"
      },
    };

    axios
      .get("https://portal.gatewaybaptist.com/api/Badges", config)
      .then((response) => (this.badges = response.data));
  },
};
</script>
